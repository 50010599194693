.pageLoader {
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  margin: 0 auto;

  &_big svg {
    margin-top: 10px;
    margin-left: 20px;
  }
}