@import "../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../shared/ui-kit-2/_constants/transition-mixins";

.WavSurfer {
  display: grid;
  align-items: center;
  grid-template-columns: 32px 1fr;
  grid-gap: 8px;

  &_black {
    .ActionButton {
      color: $dark-text;
      border-color: $border-gray;
    }

    .Duration {
      color: $dark-text;
    }
  }

  &_withDuration {
    grid-template-columns: 32px 1fr 36px;
  }
}

.ActionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  padding: 8px;
  background: transparent;
  color: $dark-text;
  border-radius: 30px;
  border: 1px solid #BFCCD7;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }
}

.Audio {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  & > div {
    width: 100%;
  }
}

.Duration {
  color: $white-bg;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}