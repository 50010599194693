@import "../../shared/ui-kit-2/_constants/typography";
@import "../../shared/ui-kit-2/_constants/colors";
@import "../../shared/ui-kit-2/_constants/transition-mixins";
@import '../../app/global-styles/vars.scss';

.Container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.InputsRow {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
}

.Input {
  width: 100%;
  height: 62px;
  background: none;
  border-radius: 12px;
  @include font-gilroy-27;
  font-weight: 400;
  color: $dark-text;
  text-align: center;
  border: 1px solid $border-gray;
  outline: none;
  @include transition-default(all);

  &::placeholder {
    color: $border-gray;
  }

  &:focus::placeholder {
    color: transparent;
  }

  &:not(:placeholder-shown) {
    border-color: $green-dark;
  }

  &_error {
    border-color: $red-alert !important;
  }

  &_disabled {
    opacity: 0.3;
  }

  @media (max-width: $lk-mobile-l) {
    width: 100%;
    height: 62px;
  }
}

.Bottom {
    display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.DidntGetCode {
  @include font-rubik-14;
  color: $dark-text;
  flex-grow: 1;
}

.ClickToResend {
  @include font-rubik-14;
  font-weight: 700;
  text-decoration: underline;
  color: $dark-text;
}