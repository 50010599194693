
.Top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  z-index: 2;
}

.Modems {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Title {
  color: #32435B;
  font-family: Gilroy, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.Stickers {
  display: flex;
  grid-gap: 8px;
  flex-wrap: wrap;
  z-index: 1;
}

.Background {
  position: absolute;
  bottom: -10px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background: rgba(189, 229, 197, 0.50);
  color: #26A632;
  border-radius: 50%;

  * {
    width: 60px;
    height: 60px;
  }
}

.EmptyModems {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 16px;
  margin: 16px 0;
  padding: 16px 0;

  &__Title {
    color: #32435B;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Gilroy, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  &__Buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 16px;
  }
}

.Button {
  margin-top: 16px;
}

.Slider {
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% + 48px);
  z-index: 1;

  &__Background {
    position: absolute;
    width: 100%;
    height: calc(100% - 48px);
    z-index: 2;
    pointer-events: none;
  }
}

.Slide {
  width: 171px;
  padding: 16px;
  background: #F6F6FB;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &__Name {
    color: #32435B;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Gilroy, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  &__Id {
    margin-bottom: 8px;
    color: #7B8AA0;
    font-family: Gilroy, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  }

  &__Statuses {
    display: flex;
    align-items: center;
    grid-gap: 12px;
  }
}