
.Top {
  display: flex;
  align-items: center;
  height: 46px;
  margin-bottom: 16px;
}

.Title {
  color: #32435B;
  font-family: Gilroy, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
}

.Content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 178px 235px 116px;
  grid-gap: 24px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 178px 178px 235px 395px 116px;
  }
}

.Card {
  &__Top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__StickerRow {
    display: flex;
    align-items: center;
  }

  &__Title {
    margin-right: 16px;
    color: #32435B;
    font-family: Gilroy, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  &__Background {
    position: absolute;
    bottom: -10px;
    right: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: rgba(189, 229, 197, 0.50);
    border-radius: 50%;
  }

  &__Stickers {
    position: relative;
    display: flex;
    grid-gap: 8px;
    flex-wrap: wrap;
    z-index: 1;
  }
}