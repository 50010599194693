@import "../../../shared/ui-kit-2/_constants/colors";

.Form {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  flex-grow: 1;
  width: 450px;
  max-width: 100%;
}

.AudioUpload {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #6c6c6c;

  &_error {
    .UploadBox {
      border-color: $red-alert;
    }
  }
}

.UploadBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  border: 2px dashed $border-gray;
  border-radius: 20px;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  text-align: center;

  &:hover {
    background-color: #f1f1f1;
  }

  &_error {
    border: 1px solid $red-alert;
  }
}

.Icon {
  font-size: 24px;
  color: #6c6c6c;
}

.Link {
  color: #37a36b;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.FileInput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: $dark-text;
  cursor: pointer;
  opacity: 0;
}

.FileError {
  margin-top: -10px;
  color: $red-alert;
  font-family: Rubik, sans-serif;
  font-size: 14px;
}

.Instructions {
  font-size: 12px;
  color: #a6a6a6;
}

.UploadedFile {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  padding: 16px;
  border-radius: 20px;
  border: 1px solid var(--border-gray, #BFCCD7);

  &__Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 8px;
  }

  &__Delete {
    width: 32px;
    height: 32px;

    button {
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      max-width: 32px;
      max-height: 32px;
    }
  }

  &__Info {
    display: flex;
    align-items: center;
    grid-gap: 12px;
  }

  &__Name {
    max-width: 50%;
    color: $dark-text;
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__Size {
    color: $text-gray;
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }

  &_error {
    border-color: $red-alert;
  }
}

.Submit {
  margin-top: auto;
}

.Row {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}