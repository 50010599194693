@import '../../../../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../../../../shared/ui-kit-2/_constants/colors.scss';

.Container {
  display: flex;
  flex-direction: row;
  column-gap: 16px;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
    row-gap: 16px;
  }
}