@import "../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../shared/ui-kit-2/_constants/colors";

.CallAutoResponseRules {
  position: relative;
}

.CardAdditional {
  overflow: hidden;
}

.Info {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}

.Audio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 16px;
  background: #32435B;
  border-radius: 20px;

  &__Wrapper {
    height: 32px;
  }

  &__Close {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    background: transparent;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      opacity: 0.7;
    }

    svg {
      width: 14px;
      height: 14px;
    }
  }
}

.Actions {
  display: flex;
  align-items: center;
  grid-gap: 8px;
}

.Action {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  color: $text-gray;
  cursor: pointer;
  transition: 0.3s;
  border: none;

  &:hover {
    opacity: 0.7;
  }
}

.Contact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
  }

  &__name {
    @include font-gilroy(12px, 120%, 500);
    color: $text-gray;
    max-width: 117px;
    word-break: break-word;

    &_device {
      color: $green;
    }
  }

  &__phone {
    @include font-gilroy-14;
    color: $dark-text;
  }
}
