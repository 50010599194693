@import '../../_constants/colors.scss';

.Avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 102px;
  height: 102px;
  background: $badge-gray;
  padding: 11px;
  border-radius: 50%;

  svg {
    width: 42px;
    height: 42px;
  }
}