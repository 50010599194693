
.TableHeader {
  width: 100%;
  height: 54px;
  padding: 8px 12px;
  border-radius: 12px;
  border: 1px solid #BFCCD7;
  background: #F6F6FB;
}

.Cols {
  position: relative;
  display: grid;
  width: 100%;
  height: 100%;
}

.OverflowedText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Col {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  padding: 0 12px;
  border-left: 0.5px solid #BFCCD7;
  border-right: 0.5px solid #BFCCD7;

  &:first-child {
    align-items: flex-start;
    justify-content: center;
    border-left: none;
    padding-left: 13px;
  }

  &:last-child {
    align-items: flex-end;
    justify-content: center;
    border-right: none;
    padding-right: 13px;
  }

  &__Name {
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;

    &_withoutFilter {
      margin: auto 0;
    }
  }

  &__FilterRow {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    width: 100%;
    max-width: max-content;
    color: #7B8AA0;
    font-family: Gilroy, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    transition: 0.3s;
    cursor: pointer;
    user-select: none;

    &_textField {}

    &:hover {
      opacity: 0.7;
    }
  }
}

.Filter {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  width: 100%;
  height: max-content;
  max-height: 232px;
  overflow: scroll;
  padding: 8px 12px;
  background: #FFF;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.10);
  z-index: 1000;

  &__Text {
    color: #7B8AA0;
    font-family: Gilroy, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
}

.Postfix {
  position: absolute;
  top: calc((100% - 9px) / 2);
  right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.IconContainer {
  display: flex;
  justify-content: center;
  color: #7B8AA0;

  svg {
    width: 9.3px;
    height: 9.3px;
  }
}