@import "../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../app/global-styles/vars";

.Paper {
  width: 453px !important;
  padding: 0 !important;;
  border-radius: 20px !important;

  @media (max-width: $lk-mobile-s) {
    margin: 16px !important;
  }
}

.Title {
  width: calc(100% - 36px);
  color: $dark-text;
  font-family: Gilroy, sans-serif;
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
}

.Subtitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 12px;
  margin-bottom: 20px;

  &__Text {
    width: 100%;
    color: $dark-text;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Rubik, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &__Alert {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    min-width: 48px;
    height: 48px;
    min-height: 48px;
    background: #FFCBCB;
    border-radius: 17px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.Actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 8px;

  button {
    height: 60px;
  }
}

.CloseButton {
  position: absolute;
  top: 27px;
  right: 18px;
  width: 24px;
  height: 24px;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }
}