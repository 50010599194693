
.ControlHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px 24px 16px 24px;
}

.TitleContainer {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}

.Title {
  color: #32435B;
  font-family: 'Gilroy', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1;
}

.Counter > div {
  color: var(--Dark-Text, #32435B);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}

.Actions {
  display: flex;
  align-items: center;
  grid-gap: 16px;
}