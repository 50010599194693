@import "../../../../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../../../../shared/ui-kit-2/_constants/colors";
@import '../../../../../../../../../app/global-styles/vars';

.Card {
  width: 100%;
  overflow: hidden;
  position: relative;

  @media (max-width: $lk-mobile-l) {
    min-height: calc(100vh - 223px);
    height: 100%;
  }

  @media (max-width: 1279px) {
    min-height: calc(100vh - 223px);
    height: 100%;
  }
}

.NewCallBtn {
  height: 48px;
  padding: 0 25px;
}