@import "../../_constants/colors";
@import "../../_constants/typography";
@import "../../_constants/transition-mixins";

@keyframes dropdown-slide {
  from {
    top: 52px;
    opacity: 0
  }
  to {
    top: 64px;
    opacity: 1
  }
}

.select {
  display: flex;
  flex-direction: column;

  &_disabled {
    opacity: 0.4;
  }

  &_label {
    height: 20px;
    @include font-rubik-14;
    line-height: 20px;
    margin-bottom: 8px;
    color: $dark-text;

    & > span {
      color: $red-alert;
    }
  }

  &_area {
    position: relative;
    width: 100%;
    height: 56px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;

    & > input {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      border-radius: 20px;
      box-sizing: border-box;
      padding: 0 0 0 20px;
      border: none;
      color: $dark-text;
      @include font-rubik-14;
      background: none;
      outline: none;
      z-index: 1;
      overflow: hidden;
      text-overflow: ellipsis;

      &::placeholder {
        color:$text-gray;
        text-overflow: ellipsis;
      }

      &:disabled {
        cursor: default;
      }
    }

    &_background {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 56px;
      box-sizing: border-box;
      border-radius: 20px;
      border: 1px solid $border-gray;
      pointer-events: none;
      z-index: 0;
      @include transition-default(border-color, border-radius);

      &_error {
        border: 1px solid $red-alert;
      }

      &_opened {
        border: 1px solid $green;
      }
    }

    &_arrow {
      cursor: pointer;
      width: 14px;
      height: 14px;
      @include transition-default(transform);

      &_active {
        transform: rotateZ(180deg);
      }
    }

    &_clear {
      cursor: pointer;
      width: 20px;
      height: 20px;
      @include transition-default(opacity);
      z-index: 2;

      &:hover {
        opacity: 0.7;
      }
    }

    //& > input:not(:placeholder-shown):not(:focus) ~ &_additionalContentGroup {
    //  background: #fff;
    //}
    //
    //& > input:disabled ~ &_background {
    //  background: rgba(229, 234, 237, 0.50);
    //}

    &_dropdown {
      position: absolute;
      top: 64px;
      z-index: 10;
      max-height: 266px;
      overflow: auto;
      width: 100%;
      background: $white;
      border-radius: 20px;
      box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.13);
      box-sizing: border-box;
      animation: dropdown-slide 0.2s ease-out;

      &_content {
        padding: 6px;
        box-sizing: border-box;
          display: flex;
  flex-direction: column;

        &_item {
          border: none;
          background: none;
          padding: 7px 6px;
          height: 32px;
          box-sizing: border-box;
          @include font-rubik(14px, 14px, 400);
          border-radius: 10px;
          cursor: pointer;
            display: flex;
  flex-direction: row;
          align-items: center;

          &_disabled {
            opacity: 0.3;
            cursor: default;
          }

          &:hover:not(&_disabled) {
            background: $bg;
          }

          &_text {
            color: $dark-text;
            flex-grow: 1;
            word-break: break-word;
            overflow: hidden;
            text-overflow: ellipsis;
            font: inherit;
            white-space: nowrap;
            text-align: left;
          }
        }

        &_notfound {
          @include font-rubik-14;
          color: $text-gray;
          padding: 6px 14px;
        }
      }
    }
  }

  &_helperText {
    margin-top: 8px;
    @include font-rubik-12;
    color: $text-gray;
    word-break: break-word;
  }

  &_error {
    margin-top: 8px;
    @include font-rubik-14;
    color: $red-alert;
    word-break: break-word;
  }
}

.AdditionalContent {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  align-items: center;
  justify-items: center;
  z-index: 2;
  background: transparent;
  height: 53px;
  border-radius: 0 20px 20px 0;
  margin-top: 1px;
  margin-right: 14px;

  &__validationElement {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__postfix {
    @include font-rubik-14;
    line-height: 20px;
    color: $text-gray;
    display: flex;
    align-items: center;
    justify-content: center;

  }
}