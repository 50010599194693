@import "../../../../app/global-styles/vars";
@import "../../_constants/typography";
@import "../../_constants/colors";
@import "../../_constants/transition-mixins";

.Button {
    display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 8px;
  @include font-gilroy-16;
  @include transition-default(all);
  width: 100%;
  border-radius: 20px;
  cursor: pointer;
  border: none;
  height: 60px;

  @media (max-width: $lk-mobile-l) {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    border-radius: 15px;
  }

  &:disabled {
    cursor: default;
  }

  &_green {
    height: 60px;
    background: $green;
    color: $white;
    border: 1px solid transparent;

    &:not([disabled]):hover {
      background: $green-dark;
    }

    &:disabled {
      background: $light-green;
    }
  }

  &_gray {
    background: $button-gray;
    color: $dark-text;

    &:not([disabled]):hover {
      background: $green;
      color: $white;
    }

    &:disabled {
      opacity: 0.7;
    }
  }

  &_white {
    background: $white;
    color: $dark-text;
    border: 1px solid transparent;
    column-gap: 16px;

    &:not([disabled]):hover {
      background: $white;
      color: $dark-text;
      border: 1px solid $green;
    }

    &:disabled {
      opacity: 0.7;
    }
  }

  &_danger {
    background: transparent;
    color: $red-alert;
    border: 1px solid $red-alert;

    &:not([disabled]):hover {
      opacity: 0.8;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  &_small {
    column-gap: 8px;
  }

  &_outlined {
    color: $dark-text;
    background: transparent;
    border: 1px solid $border-gray;

    &_greenBorder {
      border-color: $green;
    }

    &:not([disabled]):hover {
      background: $green;
      border-color: $green;
      color: $white;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  &_greenText {
    color: $green;
    border-color: $green;

    &:not([disabled]):hover {
      opacity: 0.9;
    }
  }

  &_small {
    column-gap: 8px;
  }

  &__IconContent {
    display: flex;
    align-items: center;
    justify-content: center;

    &_reverse {
      flex-direction: row-reverse;
    }
  }

  &__IconText {
    margin-left: 8px;
  }
}
