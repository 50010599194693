@import '../../../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../../../shared/ui-kit-2/_constants/typography.scss';

.CardAdditional {
  position: relative;
  min-height: calc(100vh - 223px);
  overflow: auto;
}

.ScrollableContent {
  overflow: auto;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-bottom: 10px;
  padding: 0 16px 16px 16px;
}

.ButtonContainer {
  width: 100%;
  margin-top: auto;
  padding: 16px 24px 24px 24px;
}