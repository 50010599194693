@import "../../_constants/typography";
@import "../../_constants/colors";
@import "../../_constants/transition-mixins";

.Counter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  @include font-rubik-12;
  padding: 0 12px;
  color: $green;
  border: 1px solid $green;
  background: transparent;
  border-radius: 43px;
  width: max-content;
  z-index: 2;

  &_green {
    color: $green;
    border-color: $green;
  }

  &_red {
    color: $red-alert;
    border-color: $red-alert;
  }

  &_filled {
    border: none;
    background: $badge-gray;
    color: $dark-text;
    @include font-rubik-16;
  }

  &_small {
    height: 21px;
    padding: 0 8px;
    @include font-rubik-12;
  }
}