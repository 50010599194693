
.SelectSubscription {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
}

.Card {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  padding: 16px;
  border-radius: 20px;
  border: 1px solid #BFCCD7;
  background: transparent;

  &__Row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__Name {
    color: #7B8AA0;
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2;
  }

  &__Value {
    color: #32435B;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Gilroy, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
}

.Available {
  display: flex;
  align-items: center;
  grid-gap: 24px;

  &__Item {
    display: flex;
    align-items: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Gilroy, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
  }

  &__Dots {
    display: inline-block;
    padding: 0 4px;
  }

  &__Count {
    color: #26A632;
  }
}

.Button {
  width: 100%;

  button {
    width: 100%;
  }
}
