
.Checkboxes {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
}

.Checkbox {
  display: none;

  &__Container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 8px;
    cursor: pointer;
  }

  &__Text {
    color: #7B8AA0;
    font-family: Gilroy, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    font-variant-numeric: tabular-nums;

    &_checked {
      color: #32435B;
    }
  }

  &__Icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12px;
    height: 12px;
    color: #7B8AA0;
    cursor: pointer;
    transition: 0.3s;

    svg {
      width: 12px;
      height: 12px;
    }

    &_checked {
      color: #39BE46;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}

.Search {
  &__Input {
    width: 100%;
    border: none;
    outline: none;
  }
}

.TextField {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  input {
    width: 100%;
    color: #32435B;
    font-family: Gilroy, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    border: none;
    outline: none;
  }

  &__Icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
}

.DatePickers {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.DatePicker {
  width: 188px;
}

.Icon {
  display: flex;
  justify-content: center;
  align-items: center;
}