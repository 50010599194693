@import "../../_constants/typography";
@import "../../_constants/colors";
@import "../../_constants/transition-mixins";

.Sticker {
  display: flex;
  align-items: center;
  justify-content: center;
  @include font-rubik-12;
  width: max-content;
  min-width: max-content;
  border-radius: 39px;
  user-select: none;
  color: $dark-text;
  padding: 8px 12px;

  &_lightGreen {
    background: $light-green;
  }

  &_green {
    background: $green;
    color: $white;
  }

  &_greenOutline {
    color: $green-dark;
    border: 1px solid $green-dark;
  }

  &_yellow {
    background: $yellow;
  }

  &_red {
    background: $red-alert;
    color: $white;
  }

  &_blue {
    background: $blue;
  }

  &_purple {
    background: $purple;
  }

  &_grey {
    color: $dark-text;
    background: #C9CAD7;
  }
}