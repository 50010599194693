@import "../../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../../shared/ui-kit-2/_constants/typography";

.Container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.Overview {
  display: flex;
  flex-direction: column;
  grid-gap: 28px;
  padding: 24px 0;
  border-radius: 20px;
  background: $sidebar-link-active;
}

.OverviewHeader {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  &__Title {
    @include font-rubik-16;
    color: $dark-text;
  }

  &__Statuses {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 16px;
  }
}

.OverviewStatusItem {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;

  &__GreenCircle {
    width: 10px;
    height: 10px;
    background: $green-dark;
    border-radius: 100%;
  }

  &__RedCircle {
    width: 10px;
    height: 10px;
    background: $red-alert;
    border-radius: 100%;
  }

  &__Text {
    @include font-rubik-12;
    color: $dark-text;
  }
}

.OverviewBody {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  height: 100%;
  max-height: 371px;
  overflow-y: auto;
  padding: 0 16px;
}

.CallStatus {
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid $border-gray;

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  &__Top {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    justify-content: space-between;
    align-items: center;
  }

  &__Receiver {
    display: flex;
    flex-direction: row;
    column-gap:  8px;
    align-items: center;
    @include font-rubik-16;

    &__Contact {
      color: $dark-text;
    }

    &__Phone {
      color: $text-gray;
    }
  }

  &__Red {
    @include font-rubik-12;
    color: $red-alert;
  }

  &__Green {
    @include font-rubik-12;
    color: $green-dark;
  }
}

.Button {
  margin-top: auto;
}