
.Sticker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  padding: 4px 8px;
  border-radius: 43px;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;

  &_red {
    background: #EDD1D1;
    color: #DC5050;
  }

  &_green {
    background: #BDE5C5;
    color: #26A632;
  }

  &_grey {
    background: #E4E8ED;
    color: #32435B;
  }
}