@import "../../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../../shared/ui-kit-2/_constants/typography";

.Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 8px;
  margin-top: auto;

  button {
    height: 60px;
  }
}