.Subscription {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;

  &__Card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-radius: 20px;
    background: transparent;
    transition: border 0.2s, box-shadow 0.2s;
    box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.07);
    cursor: pointer;

    &:hover {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &_selected {
      border-color: #007bff;
    }
  }

  &__Label {
    color: #32435B;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Gilroy, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  &__Circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #BFCCD7;
    transition: background-color 0.2s, border-color 0.2s;
    overflow: hidden;

    &_filled {
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      background-color: #39BE46;
      border-radius: 50%;
    }
  }
}
