@import '../../../../../../app/global-styles/vars.scss';
@import '../../../../../../shared/ui-kit-2/_constants/colors.scss';
@import '../../../../../../shared/ui-kit-2/_constants/typography.scss';
@import '../../../../../../shared/ui-kit-2/_constants/transition-mixins';

.PaymentMethodCard {
  display: flex;
  flex-direction: column;
  background: $white-bg;
  row-gap: 24px;
  border-radius: 20px;
  padding: 16px 24px 26px 24px;
  width: 100%;
  box-shadow: 0 6px 24.2px 0 rgba(0, 0, 0, 0.07);
  cursor: pointer;
  @include transition-default(box-shadow);

  &:hover {
    outline: 1px solid $green;
  }

  &_active {
    outline: 1px solid $green;
    box-shadow: none;
  }

  &_disabled {
    cursor: default;

    &:hover {
      outline: none;
    }
  }
}

.Title {
  display: flex;
  grid-gap: 16px;
  @include font-gilroy-20;
  color: $dark-text;
}


.TitleAndRadio {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  width: 100%;
  justify-content: space-between;
  height: 25px;
}

.SubscriptionInfo {
  display: flex;
  flex-direction: row;
  column-gap: 58px;
  padding-top: 16px;
  border-top: 1px solid $bg-gray;
}

.SubscriptionWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
}

.SubscriptionTitle {
  @include font-rubik-14;
  color: $text-gray;
}

.SubscriptionValue {
  @include font-gilroy-16;
  color: $dark-text;
}

.ErrorSubscription {
  color: $red-alert;
}

.TariffMonth {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  @include font-gilroy-27;
  color: $dark-text;
  margin-top: 91px;

  @media (max-width: $lk-mobile-l) {
    margin-top: 22px;
  }
}

.TariffAnnualCurrent {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  @include font-gilroy-27;
  color: $dark-text;
  margin-top: 124px;

  @media (max-width: $lk-mobile-l) {
    margin-top: 22px;
  }
}

.TariffAnnual {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  @include font-gilroy-27;
  color: $dark-text;
  margin-top: 122px;

  @media (max-width: $lk-mobile-l) {
    margin-top: 22px;
  }
}

.AnnualTariffWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.AnnualDescription {
  @include font-rubik-14;
  color: $dark-text;
  width: 236px;
}

.Disable {
  opacity: 0.3;
}

.Gifts {
  color: $green-dark;
  @include font-rubik-16;
}

.Checkbox {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: $bg-gray;
  position: relative;
  min-width: 16px;
}

.Active {
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    background: $green;
    border-radius: 100%;
    transform: translate(-50%, -50%);
  }
}

.Badge {
  display: flex;
  align-items: center;
  border: 1px solid $green-dark;
  border-radius: 43px;
  padding: 5.5px 8px;
  width: 59px;
  height: 25px;
  @include font-rubik-12;
  color: $green-dark;
}

.ChildrenPaymentCard {

}