@import "../../_constants/typography";
@import "../../_constants/colors";
@import "../../_constants/transition-mixins";

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 48px;
  min-height: 48px;
  width: 48px;
  min-width: 48px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    cursor: default;
    background: $white;
    opacity: 0.5;
  }

  &_outlined {
    border: 1px solid $border-gray;
    background: transparent;
  }

  &_filled {
    background: $bg-gray-2;
  }

  &_cancel {
    background: $red-alert;
    color: $white
  }

  &_alertOutlined {
    color: $red-alert;
    border: 1px solid $red-alert;
    background-color: transparent;
  }
}