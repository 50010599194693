
.EventLog {
  grid-column: span 2;
  grid-row: span 2;
  padding: 24px;
  border-radius: 20px;
  background: #F6F6FB;

  &__Top {
    margin-bottom: 20px;
  }

  &__Title {
    color: #32435B;
    font-family: Gilroy, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
  }

  @media screen and (max-width: 1200px) {
    grid-row: auto / auto;
  }
}

.EmptyEventLog {
  &__Image {
    display: flex;
    justify-content: center;
    height: 213px;
  }

  &__Text {
    margin: 8px 0;
    color: #32435B;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Gilroy, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  &__SubText {
    color: #32435B;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Gilroy, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.2;
  }
}