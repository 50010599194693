@import "../../../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../../../shared/ui-kit-2/_constants/colors";

.CardAdditional {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.ScrollableContent {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(333px, 1fr));
  gap: 10px;
  padding: 0 24px 24px 24px;
  overflow: auto;
}