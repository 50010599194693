@import "../../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../../shared/ui-kit-2/_constants/colors";
@import '../../../../../../app/global-styles/vars';

.CardAdditional {
  width: 100%;
  overflow: hidden;
  position: relative;

  @media (max-width: $lk-mobile-l) {
    min-height: calc(100vh - 223px);
    height: 100%;
  }

  @media (max-width: 1279px) {
    min-height: calc(100vh - 223px);
    height: 100%;
  }
}

.NewCallBtn {
  height: 48px;
  padding: 0 25px;
}

.Time {
  @include font-gilroy-14;
  color: $dark-text;
}

.Counter {
  display: flex;
  align-items: center;
  width: 100%;

  div {
    font-size: 14px !important;
  }

  &__Postfix {
    margin-left: auto;
  }
}

.Actions {
  display: flex;
  grid-gap: 8px;
  align-items: center;
}

.Action {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $text-gray;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    opacity: 0.7;
  }
}