@import "../../../../../../shared/ui-kit-2/_constants/colors";
@import "../../../../../../shared/ui-kit-2/_constants/typography";
@import '../../../../../../app/global-styles/vars.scss';

.Card {
  width: 100%;
  min-height: calc(100vh - 58px - 114px - 6px);
  margin-bottom: -114px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  @media (max-width: $lk-mobile-l) {
    min-height: calc(100vh - 223px);
    margin-bottom: 0;
  }
}