.RangeSelector {
  display: flex;
  align-items: center;
  grid-gap: 4px;

  &__Input {
    width: 36px;
    height: 20px;
    text-align: center;
    background: #F6F6FB;
    color: #32435B;
    font-family: Gilroy, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    border-radius: 4px;
    border: 1px solid #BFCCD7;
    outline: none;
  }

  &__SliderWrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 108px;
    height: 36px;
    padding: 0 4px;
  }

  &__Track {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 4px;
    background-color: #e0e0e0;
    border-radius: 3px;
  }

  &__Thumb {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8px;
    height: 8px;
    margin-top: 4px;
    background-color: #4caf50;
    border-color: #4caf50;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
  }

  &__ThumbLabel {
    position: absolute;
    top: -24px;
    padding: 2px 6px;
    background: #4caf50;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
  }
}
