@import "../../../../app/global-styles/vars";
@import "../../_constants/typography";
@import "../../_constants/colors";
@import "../../_constants/transition-mixins";

@keyframes show-drawer {
  from {
    opacity: 0;
    padding-right: 0;
  }
  to {
    opacity: 1;
    padding-right: 8px;
  }
}

@keyframes show-drawer-content {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Drawer {
  position: fixed;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  background: rgba(0, 0, 0, 0.61);
  top: 0;
  right: 0;
  z-index: 999;
  padding: 8px;
  box-sizing: border-box;
  overflow: scroll;
  overscroll-behavior: none;
  animation: show-drawer 0.1s ease;

  &_scrollOff {
    overflow-y: hidden !important;
  }

  @media (max-width: $lk-mobile-l) {
    padding: 0;

    .Content {
      max-width: 343px;
      min-width: auto;
      width: calc(100% - 32px);
      height: 100%;
      padding: 32px 16px;
      border-radius: 20px 0 0 20px;
      overflow-y: scroll;
    }

    .Title {
      font-size: 27px;
    }

    .Close {
      top: 35px;
      right: 16px;
    }

    &_fullWidth {
      .Content {
        max-width: 100%;
        width: 100%;
        border-radius: 20px 0 0 0;
      }
    }
  }
}

.Content {
  position: relative;
  margin-left: auto;
  min-width: 431px;
  width: max-content;
  min-height: calc(100vh - 31px);
  display: flex;
  flex-direction: column;
  background: $white-bg;
  padding: 32px;
  box-sizing: border-box;
  border-radius: 30px;
  animation: show-drawer-content 0.2s ease-out;

  &_limitedWidth {
    max-width: 552px;
  }
}

.Close {
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 32px;
  top: 48px;
  cursor: pointer;
  border: none;
  width: max-content;
  height: max-content;
  padding: 0;
  z-index: 1000;
}

.Title {
  @include font-gilroy-48;
  color: $dark-text;
  padding-right: 32px;
  margin-bottom: 32px;
  word-break: break-word;
}

.Body {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}

.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  padding-bottom: 80px;
}