@import "../../../shared/ui-kit-2/_constants/colors";

.Form {
  display: flex;
  flex-direction: column;
  grid-gap: 21px;
  flex-grow: 1;
  width: 450px;
  max-width: 100%;
}

.Actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 8px;
  margin-top: auto;

  button {
    height: 60px;
  }

  &_withDelete {
    grid-template-columns: 60px 1fr 1fr;
  }

  &__Delete {
    width: 60px;
  }
}

.WriteTheAnswer {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  padding: 20px;
  background: #EEEEF7;
  border-radius: 20px;

  &__Title {
    color: #32435B;
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  &__Divider {
    width: 100%;
    height: 1px;
    background: $border-gray;
  }

  &__Radios {
    display: flex;
    align-items: center;
    grid-gap: 16px;
  }
}