@import "../../../../app/global-styles/vars";
@import "../../../../shared/ui-kit-2/_constants/typography";
@import '../../../../shared/ui-kit-2/_constants/colors';

.ProfilePage {
  position: relative;
  margin-bottom: 100px;
}

.Tabs {
  position: sticky;
  top: 0;
  margin-top: -32px;
  padding: 32px 0 16px;
  background: rgba($page-wrapper, 0.7);
  z-index: 10;
  backdrop-filter: blur(10px);
}

.Cols {
  display: flex;
  flex-direction: column;
  grid-gap: 32px;
  width: 730px;
  max-width: 100%;
  margin: 16px auto 0;

  @media (max-width: $lk-tablet) {
    grid-template-columns: 1fr;
  }

  @media (max-width: $lk-mobile-l) {
    margin-bottom: 39px;
    display: flex;
    flex-direction: column;
  }
}

.Col {
  display: flex;
  flex-direction: column;
}

.Card {
  &__Title {
    margin-bottom: 25px;
    color: $dark-text;
    font-family: Gilroy, sans-serif;
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
  }

  &__Grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
  }
}




.ProfileForm {
  @media (max-width: $lk-mobile-l) {
    .ProfileFormGrid {
      grid-template-columns: repeat(1, 1fr);
    }

    .Actions {
      justify-content: space-between;
      margin-top: 0;
    }

    .Nickname {
      font-size: 22px;
    }

    .NicknameHeader {
      margin-bottom: 0 !important;
    }

    .NicknameSection {
      display: flex;
      flex-direction: column;
      row-gap: 5px;
    }

    .Divider {
      width: 100%;
      height: 1px;
      margin: 16px 0;
      background: $border-gray;
    }

    .MobileGrid {
      display: grid;
      grid-template-columns: repeat(2, 150px);
      grid-row-gap: 16px;
      grid-column-gap: 44px;

      &__FieldName {
        margin-bottom: 5px;
        color: $text-gray;
        font-family: Rubik, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.2;
      }

      &__FieldValue {
        font-family: Rubik, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        word-break: break-all;
      }
    }

    .ChangePassword, .Edit {
      width: calc(50% - 3.5px);
    }

    .DevicesAmount {
      font-size: 14px;
    }
  }

  @media (max-width: $lk-mobile-s) {
    .MobileGrid {
      grid-template-columns: repeat(2, calc(50% - 22px));
    }
  }
}

.ProfileFormGrid {
  display: grid;
  column-gap: 16px;
  row-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
}

.Actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  &_submit {
    width: 212px;
    height: 48px;
    margin-left: auto;
  }
}

.fullSizeItem {
  grid-column: span 2;
}

.Controls {
  display: flex;
  justify-content: space-between;
}

.ChangePassword {
  button {
    width: 160px;
    height: 48px;
  }
}

.Header {
  display: flex;
  flex-direction: row;
  column-gap: 26px;
  align-items: center;
}

.NicknameSection {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  overflow: hidden;
}

.Nickname {
  max-width: 100%;
  text-overflow: ellipsis;
  height: 28px;
  color: $dark-text;
  white-space: nowrap;
  overflow: hidden;
  @include font-gilroy-27;
}

.DevicesAmount {
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  @include font-rubik-14;
}

.ProfileFormContainer {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.CheckBoxLegal {
  margin: 24px 0;

  label {
    cursor: pointer;
    align-items: center;
    div:nth-child(1) {
      @include font-gilroy-20-bold;
      color: $dark-text;
      margin: 0;
    }
  }
}

.Title {
  @include font-gilroy-20-bold;
  color: $dark-text;
  margin: 24px 0;
}

.AddressTitle {
  @include font-gilroy-20-bold;
  color: $dark-text;
}

.ProfileFormWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.SameWord {
  @include font-gilroy-16;
  color: $green-dark;
}

.DeliverySameTitle {
  @include font-gilroy-16;
  color: $dark-text;
  margin: 16px 0;
}