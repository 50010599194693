@import "../../../../../shared/ui-kit-2/_constants/typography";
@import "../../../../../shared/ui-kit-2/_constants/colors";

.SimpleText {
  @include font-gilroy-14;
  color: $dark-text;
}

.Device {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__name {
    @include font-gilroy(12px, 120%, 500);
    color: $green;
    width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__phone {
    @include font-gilroy-14;
    color: $dark-text;
  }

  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
  }
}

.SimpleTextWithRightBorder {
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 34px;
  margin-right: -13px;
  padding-right: 13px;
  @include font-gilroy-14;
  color: $dark-text;
  border-right: 1px solid $border-gray;
}

.MessageText {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 183px;
  white-space: nowrap;
}

.Actions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  column-gap: 8px;

  svg {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.Header {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 24px;
  justify-content: flex-start;

  &__date {
    @include font-gilroy-14;
    color: $dark-text;
  }

  &__sentAndReceived {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    column-gap: 16px;

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      column-gap: 8px;
      @include font-rubik-12;
      color: $text-gray;
    }
  }
}